.experience {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 10%;
}

.accordion {
  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
  color: var(--text-color);
}

.accordion-content {
  background-color: var(--container-color);
}

.accordion-item {
  border: none;
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  width: 1200px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.bullet-points {
  text-align: left;
}

.exp__description {
  color: var(--text-color);
  font-size: var(--h4-font-size);
}

.exp__img {
  height: 100px;
  width: 100px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.exp__info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.exp__details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.exp__title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
}

.accordion-item.active {
  background-color: var(--body-color);
}

@media screen and (max-width: 1290px) {
  .accordion-item {
    max-width: 95%;
  }
}
