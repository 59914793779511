.about {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about__description {
  width: 40%;
  font-size: var(--h3-font-size);
  display: flex;
}

.section__title {
  margin-right: 3rem;
}

.about__img {
  background: url(../../assets/profile.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgba(0, 0, 0, 0.3);
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.about__data {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  padding-bottom: 10%;
}

/* large devices */
@media screen and (max-width: 992px) {
  .about__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .about__img {
    box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.3);
    width: 200px;
    height: 200px;
  }
  .about__data {
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .about__description {
    width: 80%;
    text-align: center;
  }
}

/* small devices */
@media screen and (max-width: 350px) {
  .about__img {
    width: 180px;
    height: 180px;
  }
}
