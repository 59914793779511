.skills {
  background-image: linear-gradient(rgb(28, 123, 201), rgb(212, 235, 255));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.skills__container {
  grid-template-columns: repeat(3, 350px);
  column-gap: 3rem;
  justify-content: center;
  margin-bottom: 10%;

}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid black;
  padding: 2rem 4rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.skills__title {
  font-weight: var(--font-semi-bold);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1.5rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

i {
  font-size: 2rem;
}

img {
  height: 32px;
  width: auto;
}

/* large devices */
@media screen and (max-width: 1172px) {
  .skills{
    height: auto;
  }
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

/* medium devices */

@media screen and (max-width: 576px) {
  .skills__content {
    padding: 1rem;
  }
}

/* small devices */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }
  .skills__name {
    font-size: var(--small-font-size);
  }
}
