.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
}

.arrow-icon {
  display: flex;
  background-color: #ffffff;
  transition-property: all;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 9999px;
  border-width: 1px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
}

.arrow-icon:hover {
  transform: scaleX(var(--transform-scale-x, 1.1)) scaleY(var(--transform-scale-y, 1.1));
}

.bx-up-arrow-alt {
  font-size: 1.5rem;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .scroll-to-top {
    bottom: 4rem;
    right: 1rem;
  }
}
