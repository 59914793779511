.projects {
  background-image: linear-gradient(rgb(28, 123, 201), rgb(212, 235, 255));
}

.section__title {
  margin-bottom: var(--mb-2-5);
  padding-top: 5%;
}

.work__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding-bottom: 5%;
}

.work_content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.work__links {
  display: flex;
  gap: 1rem;
  margin-bottom: var(--mb-1-5);
}

.work__card {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

}

.work__stack {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.work__stack-item {
  font-size: var(--small-font-size);
  color: rgb(0, 0, 0);
  background-color: rgb(68, 159, 233);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.work__title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-5);
}

.work__description {
  margin-bottom: var(--mb-1);
  color: var(--text-color);
  font-size: var(--h4-font-size);
  width: 400px;
}

.work__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--container-color);
  border: 2px solid rgb(68, 159, 233);
}

.work__button:hover {
  background-color: rgb(68, 159, 233);
  color: white;
}

.img__container {
  width: 640px;
  height: auto;
}

.work__img,
.video {
  max-width: 100%;
  height: 360px;
  width: 640px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
}

/* large devices */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
    max-width: 95%;
  }

  .work__title {
    margin-bottom: 0.25rem;
  }
}

/* medium devices */
@media screen and (max-width: 992px) {
  .work__container {
    grid-template-columns: max-content;
  }

  .work__card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .work_content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 250px;
  }

  .work__stack {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video,
  .work__img {
    max-width: 500px;
    max-height: 300px;
  }
}
@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }
  .video,
  .work__img {
    max-width: 300px;
    max-height: 200px;
  }
  .work__stack {
    width: 300px;
  }
  .work__description {
    width: 300px;
  }
}
