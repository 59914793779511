.footer {
  background-color: var(--container-color);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.footer__social-icon {
  color: grey;
  font-size: 2.25rem;
  margin: 0rem 1rem;
}

.footer-info {
  text-align: center;
  color: rgb(94, 94, 94);
}

.footer__social-icon:hover {
  color: var(--title-color-dark);
}

@media screen and (max-width: 768px) {
  .footer__container {
    padding-bottom: 3.5rem;
  }
}
