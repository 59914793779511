.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  align-items: center;
  column-gap: 2.5rem;
  margin-top: 1rem;
  list-style: none;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

.nav__item :hover,
.nav__item .active {
  padding-bottom: 3px;
  border-bottom: 3px solid rgb(28, 123, 201);
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: rgb(28, 123, 201);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgb(35, 95, 145);
}

.react-toggle--checked .react-toggle-track {
  background-color: #4d4d4d;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 7px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 6px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 10px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 17px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: rgb(28, 123, 201);
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
    padding: 0 1.5rem;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--container-color);
    padding: 0.5rem 1.5rem 1rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .show-menu {
    bottom: 0;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-right: 1.6rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
    padding-right: 2.4rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .nav__item :hover {
    padding-bottom: 0;
    border-bottom: none;
  }

  .nav__item .active {
    padding-bottom: 2px;
    border-bottom: 2px solid rgb(28, 123, 201);
    width: 50%;
    margin: 0 auto;
    
  }
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    column-gap: 0;
  }
}
