.home {
  background-image: linear-gradient(rgb(28, 123, 201), rgb(212, 235, 255));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding: 0 1.5rem;
  gap: 30%;
  padding-top: 10%;
}

.home__social {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.home__social-icon i {
  font-size: 3rem;
  color: rgb(28, 123, 201);
}

.home__social-icon i:hover {
  color: rgb(0, 0, 0);
  transform: translateY(-3px);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: 1rem;
}

.home__subtitle {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-size: var(--h1-font-size);
  font-weight: var(--font-normal);
  margin-bottom: 0.5rem;
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-2);
}

.home__scroll {
  cursor: pointer;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

/* large devices */
@media screen and (max-width: 992px) {
  .home__subtitle {
    margin-bottom: var(--mb-1);
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .home__scroll {
    display: none;
  }
}
