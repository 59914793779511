@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

/* Variables */
:root {
  --header-height: 3rem;

  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 23%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --dark-body-color: hsl(var(--hue), var(--sat), 10%);
  --container-color: #fff;

  --body-font: "Karla", sans-serif;

  --big-font-size: 4.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.5rem;
  --h4-font-size: 1.125rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.15rem;
    --h4-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* Base */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: black;
  max-width: 100%;
  overflow-x: hidden;
}

body.dark {
  background-color: var(--dark-body-color);
  color: var(--body-color);
  --title-color: var(--body-color);
  --container-color: rgb(39, 39, 39);
  --text-color: #fff;
}

body.dark .accordion-item.active {
  background-color: var(--dark-body-color);
}

body.dark .footer__social-icon {
  color: #fff;
}

body.dark .work__stack-item {
  color: #fff;
  background-color: rgb(0, 50, 90);
}

body.dark .work__button {
  border: 2px solid rgb(0, 50, 90);
}
body.dark .work__button:hover {
  background-color: rgb(0, 50, 90);
}

body.dark .home {
  background-image: linear-gradient(rgb(0, 50, 90), rgb(0, 21, 39));
}
body.dark .skills {
  background-image: linear-gradient(rgb(0, 50, 90), rgb(0, 21, 39));
}
body.dark .projects {
  background-image: linear-gradient(rgb(0, 50, 90), rgb(0, 21, 39));
}

body.dark .footer-info {
  color: #fff;
}

body.dark .section__title {
  color: var(--body-color);
}
body.dark .header {
  background-color: rgb(39, 39, 39);
}
body.dark .contact__form-tag {
  background-color: transparent;
}

body.dark .home__scroll-arrow {
  color: var(--body-color);
}

body.dark .home__scroll-mouse .wheel {
  color: var(--body-color);
}

body.dark .home__title {
  color: var(--body-color);
}
body.dark .home__subtitle {
  color: var(--body-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* Reusable */

.section__title {
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* Layout */

.container {
  max-width: 1200px;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* Buttons */
.button {
  display: inline-block;
  background-color: hsl(var(--hue), var(--sat), 20%);
  color: #fff;
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--title-color-dark);
}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/* large devices */
@media screen and (max-width: 992px) {
  .button {
    padding: 1rem 1.75rem;
  }
  .button__icon {
    width: 22px;
    height: 22px;
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .section {
    padding: 2rem 0 4rem;
  }
}
@media screen and (max-width: 576px) {
}

/* small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
